import React, { useRef } from 'react';
import styled from '@emotion/styled';
import tw from "tailwind.macro";
import { useDrag, useGesture } from 'react-use-gesture';
import { useSprings, animated } from 'react-spring'
import clamp from 'lodash-es/clamp'
import debounce from 'lodash-es/debounce'
import { Lethargy } from 'lethargy'

import defaultTailwind from '../../config/theme/tailwind.default'

import  useWindowSize  from '../../hooks/useWindowSize';
import { isBrowser } from '../../helpers/utils';
;

const lethargy = new Lethargy()

const colors = Object.keys(defaultTailwind.theme.colors).map(color => color[500] ? color[500] : color)
    .filter(c => c !== 'transparent' && c !== 'white' && c !== 'current').map((c, i) => <div key={i} className="w-full h-full" css={{ backgroundColor: c }}>
        {`tile ${i}`}
    </div>)


const DragContainer = ({ children = colors }: { children: JSX.Element[] }) => {

    const STEP = useWindowSize().height;
    const index = useRef(0)
    const [props, set] = useSprings(children.length, i => ({
        y: i * STEP,
        // scale: 1,
        display: 'block'
    }))

    const bind = useGesture({
        onMouseDown: e => e.preventDefault,
        onDrag: ({ down, movement: [, my], direction: [, yDir], cancel }) => {
            const distance = Math.abs(my);
            if (down && distance > STEP / 2) {
                // @ts-ignore
                cancel((index.current = clamp(index.current + (yDir > 0 ? -1 : 1), 0, children.length - 1)))
            }
            set(i => {
                if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
                const minThreshold = distance > STEP / 5;
                const y = (i - index.current) * STEP + (down && minThreshold ? my : 0)
                const scale = down && minThreshold ? 1 - distance / STEP / 4 : 1
                return { y, scale, display: 'block' }
            })
        },
        onWheel: (props) => {
            const { wheeling: down, movement: [, tmpMy], direction: [, yDir], cancel, event, last } = props;
            const my = clamp(tmpMy, yDir > 0 ? 0 : -STEP, yDir > 0 ? STEP : 0);
            const distance = Math.abs(my);
            //remove warning syntetic event;
            event?.persist()
            const s = lethargy.check(event)


            //if scroll inertia and not last event
            if (s === false && !last) return

            const newIndex = clamp(index.current + (yDir > 0 ? 1 : -1), 0, children.length - 1)
            if (last) {
                //@ts-ignore
                cancel(index.current = newIndex)
            }
            console.log(`newIndex ${newIndex}`)

            set(i => {
                if (i < index.current - 1 || i > index.current + 1) return { display: 'none' }
                const minThreshold = distance > STEP / 3;
                const myMax = Math.min(Math.abs(my), STEP);

                const y = (i - newIndex) * STEP;
                console.log(y)

                // const scale = down && minThreshold ? 1 - (myMax / STEP) / 50 : 1
                return { y, scale: 1, display: 'block' }
            })
        },
    })

    console.log('STEP is', STEP)

    return <Container style={styles.pageCont} >
        {props.map(({ y, display }, i) => (
            <animated.div {...bind()} key={i} style={{ display, y, ...styles.containerSection }}>
                <div style={{ ...styles.section }}>
                    {children[i]}
                </div>
            </animated.div>
            ))
        }
    </Container>

}
export default DragContainer

const styles: { [key: string]: React.CSSProperties; } = {
    pageCont: {
        position: 'fixed', overflow: 'hidden', width: '100vw', height: '100vh'
    },
    containerSection: {
        position: 'absolute', width: '100vw', height: '100vh', willChange: 'transform'
    },
    section: {
        width: '100%', height: '100%', willChange: 'transform'
    }
}

const Container = styled.div`
> * {
    overflow: hidden;
}
> * > * > * {
    ${tw`
        flex
        flex-col
        content-center
        h-full
        w-screen
    `}
}
`