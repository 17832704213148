import React from 'react'
import DragContainer from '../../components/dragContainer/dragContainer';
import defaultTailwind from '../../config/theme/tailwind.default'


const colors = Object.keys(defaultTailwind.theme.colors).map(color => color[500] ? color[500] : color).filter(c => c !== 'transparent' && c !== 'white' && c !== 'current')

const Drag = () => {
    return <DragContainer>
        {colors.map((c, i) => <div key={i} style={{ backgroundColor: c, width: '100%', height: '100%' }}>
            {`index ${i}`}
        </div>)}
    </DragContainer>

}
export default Drag