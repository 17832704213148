import  {useEffect, useState} from 'react';

const DEFAULT_SZE = 1920;

export enum DEVICES {MOBILE = 'mobile', TABLET = 'tablet', DESKTOP = 'desktop'}
const BREAKPOINTS_W = {
  [DEVICES.MOBILE]: 640,
  [DEVICES.TABLET]: 1025
}

export const isDesktop = type => type === DEVICES.DESKTOP;
export const isTablet = type => type === DEVICES.TABLET;
export const isMobile = type => type === DEVICES.MOBILE;

/**
 * @param type device
 * @param sizes array of sizes 0 for mobile, 1 for tablet and 2 for desktop
 */

export const getSize = (type:DEVICES, sizes:any[]) => {
  switch(type){
    case DEVICES.MOBILE:
      return sizes[0]
    case DEVICES.TABLET:
        return sizes[1];
    case DEVICES.DESKTOP:
  return sizes[2];
}
}

function useWindowSize() {
    const isClient = typeof window === 'object';

    function getSize() {

      const width =  isClient ? window.innerWidth : DEFAULT_SZE;
      const height = isClient ? window.innerHeight : DEFAULT_SZE;

      let type = DEVICES.DESKTOP
      if(width<BREAKPOINTS_W[DEVICES.MOBILE]){
        type=DEVICES.MOBILE
      }else if(width<BREAKPOINTS_W[DEVICES.TABLET]){
        type=DEVICES.TABLET
      }
      return {
        width,
        height,
        isPortrait: width < height,
        type,
        isMobile: isMobile(type),
        isTablet: isTablet(type),
        isDesktop: isDesktop(type)
      };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    //@ts-ignore
    useEffect(() => {
      if (!isClient) return
      function handleResize() {
        setWindowSize(getSize());
      }
      handleResize()
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
  }

  export default useWindowSize